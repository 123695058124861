import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Fragment, useContext, useState, useEffect } from "react";
import { Alert, Col, Form, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Dropdown } from "semantic-ui-react";
import { BasicContext } from "../../App";
import LoadingProgressBar from "../spinners/LoadingProgressBar";

const BarClientsDropdown = (props) => {
    const { setBasicAlert, fetchURL, activeEvent } = useContext(BasicContext);
    const { t } = useTranslation();

    const [loading, setLoading] = useState(true);
    const [barClientsList, setBarClientsList] = useState(null);

    useEffect(() => {
        const barClientsList = async () => {
            const passData = {
                activeEvent: activeEvent,
            };
            try {
                const response = await fetch(`${fetchURL}/bar-clients-list-dropdown`, {
                    credentials: "include",
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(passData),
                });

                if (response.status === 200) {
                    await response.json().then((fetchedData) => {
                        console.log(fetchedData);
                        if (fetchedData.status === false || fetchedData.status === "noPermission") {
                            setBasicAlert({ variant: "danger", message: t(`error.${fetchedData.code}`), fading: null });
                        } else {
                            setBarClientsList(fetchedData.barClientsList);
                            setLoading(false);
                            setBasicAlert(null);
                        }
                    });
                }
            } catch (err) {
                setBasicAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})`, fading: null });
            }
        };

        barClientsList();
    }, []);

    return (
        <Fragment>
            <Row>
                <Col xs={12}>
                    <Form.Label>{t("sell.searchBarClient")}</Form.Label>
                </Col>
                {loading ? (
                    <Col xs={12}>
                        <LoadingProgressBar />
                    </Col>
                ) : barClientsList.length === 0 ? (
                    <Col xs={12} className="text-center">
                        <Alert variant="warning">
                            <FontAwesomeIcon icon={faExclamationTriangle} className="mr-2" /> {t("barClients.noBarClients")}
                        </Alert>
                    </Col>
                ) : (
                    <Col xs={12}>
                        <Dropdown
                            placeholder={t("searchAndSelect")}
                            fluid
                            search
                            selection
                            options={barClientsList}
                            onChange={(event, { value }) => {
                                console.log("setBarClient");
                                props.onSelectBarClient(value);
                            }}
                            className="mb-3"
                            id="barClientsList"
                            value={props.selectedBarClient}
                        />
                    </Col>
                )}
            </Row>
        </Fragment>
    );
};

export default BarClientsDropdown;
