import React, { createContext, useState, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import { BasicContext } from "../../../App";
import { LoginDataContext } from "../../structure/LoggedPages";
import AddBarClient from "./AddBarClient";
import AddPayment from "./AddPayment";
import BarClientDetails from "./BarClientDetails";
import SettleTheBill from "./SettleTheBill";

export const BarClientContext = createContext();

const BarClients = () => {
    const { currentPage } = useContext(LoginDataContext);
    const { setBasicAlert, activeEvent, fetchURL } = useContext(BasicContext);
    const { t } = useTranslation();
    const [fetchBarClient, setFetchBarClient] = useState(false);
    const [barClientData, setBarClientData] = useState(null);
    const [getBarClient, setGetBarClient] = useState({ qrCode: null, barClientID: null });
    const [loadingBarClientData, setLoadingBarClientData] = useState(false);

    useEffect(() => {
        const getBarClientData = async () => {
            const passData = {
                qrCode: getBarClient.qrCode,
                barClientID: getBarClient.barClientID,
                activeEvent: activeEvent,
            };
            try {
                const response = await fetch(`${fetchURL}/bar-client-details`, {
                    credentials: "include",
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(passData),
                });

                if (response.status === 200) {
                    await response.json().then((fetchedData) => {
                        console.log(fetchedData);
                        if (fetchedData.status === false || fetchedData.status === "noPermission") {
                            setBasicAlert({ variant: "danger", message: t(`error.${fetchedData.code}`), fading: null });
                        } else {
                            setBarClientData(fetchedData.barClientData);
                            setLoadingBarClientData(false);
                        }
                    });
                }
            } catch (err) {
                setBasicAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})`, fading: null });
            }
        };
        if (fetchBarClient) {
            setLoadingBarClientData(true);
            getBarClientData();
        }
        return () => {
            setFetchBarClient(false);
        };
    }, [fetchBarClient]);

    useEffect(() => {
        console.log(("getBarClient: ", getBarClient));
        if (getBarClient.qrCode || getBarClient.barClientID) {
            console.log("setFetchBarClient");
            setFetchBarClient(true);
        }
    }, [getBarClient]);

    useEffect(() => {
        setGetBarClient({ qrCode: null, barClientID: null });
        setBarClientData(null);
    }, [currentPage.subpage]);

    return (
        <BarClientContext.Provider value={{ barClientData, setFetchBarClient, setGetBarClient, loadingBarClientData, setLoadingBarClientData, getBarClient, setBarClientData }}>
            {currentPage.subpage === "addBarClient" && <AddBarClient />}
            {currentPage.subpage === "barClientDetails" && <BarClientDetails />}
            {currentPage.subpage === "addPayment" && <AddPayment />}
            {currentPage.subpage === "settleTick" && <SettleTheBill />}
        </BarClientContext.Provider>
    );
};

export default BarClients;
