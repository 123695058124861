import React, { Fragment, useContext, useEffect, useState } from "react";
import { BasicContext } from "../../../App";
import { useTranslation } from "react-i18next";
import { Button, Row, Col, Form } from "react-bootstrap";
import Email from "../../forms/Email";
import Password from "../../forms/Password";

const Login = (props) => {
    const { setBasicAlert, fetchURL, moduleName, validateEmail, setSessionAlert, setCheckActiveEvent } = useContext(BasicContext);

    const initialState = {
        email: "",
        password: "",
        isSubmitting: false,
    };

    const [data, setData] = useState(initialState);
    const [doFetch, setDoFetch] = useState(false);

    useEffect(() => {
        const handleLogin = async () => {
            setSessionAlert(null);
            setBasicAlert({ variant: "info", message: t("info.logging") });
            try {
                const response = await fetch(`${fetchURL}/login`, {
                    credentials: "include",
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        loginData: {
                            email: data.email[0],
                            password: data.password[0],
                            qrCode: null,
                        },
                        moduleName: moduleName,
                    }),
                });

                if (response.status === 200) {
                    await response.json().then((fetchedData) => {
                        console.log(fetchedData);
                        if (fetchedData.status === false || fetchedData.status === "noPermission") {
                            setBasicAlert({ variant: "danger", message: t(`error.${fetchedData.code}`), fading: null });
                        } else if (fetchedData.status === "inactive profile" || fetchedData.status === "reset pass") {
                            setBasicAlert({
                                variant: "danger",
                                message: t(`error.${fetchedData.code}`),
                                contactBtn: {
                                    mailAddress: "",
                                    mailTitle: fetchedData.status === "inactive profile" ? t("user.inactiveAccount") : fetchedData.status === "reset pass" ? t("user.resetPass") : "",
                                    mailSender: "",
                                },
                            });
                        } else {
                            setCheckActiveEvent({ userPrivileges: fetchedData.userPrivilages, cookieExpDate: fetchedData.cookieExpDate });
                            setBasicAlert({ variant: "success", message: t(`success.login`), fading: 800 });
                        }
                    });
                }
            } catch (err) {
                setBasicAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})`, fading: null });
            }
        };

        if (doFetch) handleLogin();

        return () => setDoFetch(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [doFetch]);

    const handleInputChange = (e) => {
        setData({ ...data, [e.target.name]: [e.target.value] });
    };

    const { t } = useTranslation();

    const handleCheckValidation = (e) => {
        e.preventDefault();
        setData({
            ...data,
            isSubmitting: true,
        });

        let errorMsg = "";
        if (!data.email) {
            errorMsg += t("error.emptyEmail");
            document.getElementById("userEmail").classList.add("is-invalid");
        } else {
            const validate = validateEmail(data.email);
            console.log("validate", validate);
            if (!validate) {
                errorMsg += validate;
                document.getElementById("userEmail").classList.add("is-invalid");
            }
        }

        if (!data.password) {
            errorMsg += t("error.emptyPassword");
            document.getElementById("password").classList.add("is-invalid");
        }

        if (errorMsg) {
            console.log(errorMsg);
            setBasicAlert({ variant: "danger", message: errorMsg });
        } else {
            setDoFetch(true);
        }
    };

    return (
        <Fragment>
            <Button variant="outline-primary" id="goBack" onClick={() => props.onSetSubPage(null)}>
                {t("goBack")}
            </Button>
            <Row>
                <Col lg={{ span: 6, offset: 3 }}>
                    <Form onSubmit={handleCheckValidation} autoComplete="off">
                        <Email
                            value={data.email}
                            onChangeValue={(e) => {
                                handleInputChange(e);
                                document.getElementById("userEmail").classList.remove("is-invalid");
                            }}
                            id="userEmail"
                            name="email"
                        />
                        <Password value1={data.password} name="password" onChangeValue={handleInputChange} option="single" />
                        <Form.Group className="text-center mb-3">
                            <Button variant="primary" type="submit" className="mt-3" disabled={doFetch ? true : false}>
                                {t("user.login")}
                            </Button>
                        </Form.Group>
                    </Form>
                </Col>
            </Row>
            <Row>
                <Col className="text-center mt-4">
                    <Button variant="link" onClick={() => props.onSetSubPage("rememberPass")}>
                        {t("dontRememberPass")}
                    </Button>
                </Col>
            </Row>
        </Fragment>
    );
};

export default Login;
