import { faCircleInfo, faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Fragment, useContext, useEffect, useState } from "react";
import { Alert, Button, Col, Container, Form, Image, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Checkbox } from "semantic-ui-react";
import { BasicContext } from "../../../App";
import NoActiveEventAlert from "../../alerts/NoActiveEventAlert";
import AmountLimit from "../../forms/AmountLimit";
import BarmanRolesDropdown from "../../forms/BarmanRolesDropdown";
import StartingPayment from "../../forms/StartingPayment";
import UsersListForBarmans from "../../forms/UsersListForBarmans";
import NavDetails from "../../navigation/NavDetails";
import { LoginDataContext } from "../../structure/LoggedPages";
import LoadingProgressBar from "./../../spinners/LoadingProgressBar";

const barClientDataStart = {
    startingPayment: "",
    amountLimit: 0,
    noLimit: false,
    ifInnerAccount: false,
};

const AddBarman = () => {
    const { setBasicAlert, QRscanner, setQRscanner, activeEvent, QRData, fetchURL, setQRData } = useContext(BasicContext);
    const { currentPage, loggedUserQR } = useContext(LoginDataContext);
    const { t } = useTranslation();

    const [selectedUser, setSelectedUser] = useState(null);
    const [barman, setBarman] = useState({ userID: null, role: null, createBarClient: false, identValue: null });
    const [disableSubmitting, setDisableSubmitting] = useState(true);
    const [fetchAddBarman, setFetchAddBarman] = useState(false);
    const [barClientData, setBarClientData] = useState(barClientDataStart);
    const [usersList, setUsersList] = useState(null); //lista userów tylko do dropdowna
    const [usersListData, setUsersListData] = useState(null); //lista userów ze szczegółowymi danymi
    const [loading, setLoading] = useState(true);

    //const [userIsBarmanAlert, setUserIsBarmanAlert] = useState(false);

    /* useEffect(() => {
        const checkIfUserHasAlreadyBarmanRole = async () => {
            try {
                const response = await fetch(`${fetchURL}/check-if-user-is-barman`, {
                    credentials: "include",
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        activeEvent: activeEvent,
                        user: selectedUser,
                    }),
                });

                if (response.status === 200) {
                    await response.json().then((fetchedData) => {
                        console.log(fetchedData);
                        setDisableSubmitting(false);
                        if (fetchedData.status === false || fetchedData.status === "noPermission") {
                            setBasicAlert({ variant: "danger", message: t(`error.${fetchedData.code}`), fading: null });
                        } else if (fetchedData.status === "userIsBarman") {
                            setUserIsBarmanAlert(true);
                        } else setUserIsBarmanAlert(false);
                    });
                }
            } catch (err) {
                setBasicAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})`, fading: null });
            }
        };

        if (selectedUser) checkIfUserHasAlreadyBarmanRole();
    }, [selectedUser]); */

    useEffect(() => {
        const addBarman = async () => {
            try {
                const response = await fetch(`${fetchURL}/add-barman`, {
                    credentials: "include",
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        activeEvent: activeEvent,
                        barman: barman,
                        barClient: barClientData,
                        loggedUserQR: loggedUserQR,
                    }),
                });

                if (response.status === 200) {
                    await response.json().then((fetchedData) => {
                        console.log(fetchedData);
                        setDisableSubmitting(false);
                        if (fetchedData.status === false || fetchedData.status === "noPermission") {
                            setBasicAlert({ variant: "danger", message: t(`error.${fetchedData.code}`), fading: null });
                        } else {
                            setBasicAlert({ variant: "success", message: t(`success.addedBarman`), fading: 2000 });
                            setBarman({ userID: null, role: null, createBarClient: false, identValue: null });
                            setSelectedUser(null);
                            setBarClientData(barClientDataStart);
                            setLoading(true);
                        }
                    });
                }
            } catch (err) {
                setBasicAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})`, fading: null });
            }
        };

        if (fetchAddBarman) addBarman();

        return () => {
            setFetchAddBarman(false);
        };
    }, [fetchAddBarman]);

    const handleCheckValidation = (e) => {
        setBasicAlert({ variant: "info", message: t("info.checkingForm") });
        e.preventDefault();
        const errorMsg = [];

        /* if (userIsBarmanAlert) {
            errorMsg.push(t("error.userIsBarmanAlready"));
        } */

        if (!selectedUser.user_id) {
            errorMsg.push(t("error.noSelectedUser"));
            document.getElementById("usersList").classList.add("is-invalid");
        } else {
            if (!selectedUser.userHasIdent && !selectedUser.partHasIdent && !barman.identValue) {
                errorMsg.push(t("error.noScannedQRCode"));
            }
        }
        if (!barman.role) {
            errorMsg.push(t("error.noSelectedRole"));
            document.getElementById("barmanRolesList").classList.add("is-invalid");
        }

        if (errorMsg.length > 0) {
            setBasicAlert({
                variant: "danger",
                message: (
                    <Fragment>
                        <p className="mb-1">{t("error.errorsInForm")}</p>
                        <ul className="mb-1">
                            {errorMsg.map((e, i) => (
                                <Fragment key={i}>
                                    <li>{e}</li>
                                </Fragment>
                            ))}
                        </ul>
                    </Fragment>
                ),
                fading: null,
            });
        } else {
            setDisableSubmitting(true);
            setBasicAlert({ variant: "info", message: t("info.addingNewBarClient") });
            setFetchAddBarman(true);
        }
    };

    useEffect(() => {
        const checkIdentAssignment = async () => {
            const passData = {
                value: QRData,
            };
            try {
                const response = await fetch(`${fetchURL}/check-assigned-ident`, {
                    credentials: "include",
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(passData),
                });

                if (response.status === 200) {
                    await response.json().then((fetchedData) => {
                        console.log(fetchedData);
                        if (fetchedData.status === false || fetchedData.status === "noPermission" || fetchedData.status === "assigned") {
                            setBasicAlert({ variant: "danger", message: t(`error.${fetchedData.code}`), fading: null });
                        } else setBarman({ ...barman, identValue: QRData });
                        setDisableSubmitting(false);
                    });
                }
            } catch (err) {
                setBasicAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})`, fading: null });
                setDisableSubmitting(false);
            }
        };

        if (QRData) checkIdentAssignment();

        return () => {
            setQRData(null);
            setQRscanner(false);
        };
    }, [QRData]);

    useEffect(() => {
        //lista userów, którzy w danym wydarzeniu nie mają jeszcze żadnego klienta
        const getUsersList = async () => {
            try {
                const response = await fetch(`${fetchURL}/users-for-barmans`, {
                    credentials: "include",
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },

                    body: JSON.stringify({ eventCode: activeEvent }),
                });

                if (response.status === 200) {
                    await response.json().then((fetchedData) => {
                        console.log(fetchedData);
                        if (fetchedData.status === false || fetchedData.status === "noPermission") {
                            setBasicAlert({ variant: "danger", message: t(`error.${fetchedData.code}`), fading: null });
                        } else {
                            setUsersList(fetchedData.usersList);
                            setUsersListData(fetchedData.usersListData);
                            setLoading(false);
                        }
                    });
                }
            } catch (err) {
                setBasicAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})`, fading: null });
            }
        };
        if (loading) getUsersList();

        return () => {
            setLoading(false);
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading]);

    return (
        <Fragment>
            <NavDetails backBtn title={`${currentPage.nav}.${currentPage.subpage}`} />
            {activeEvent === "noActiveEvent" ? (
                <NoActiveEventAlert alertTxt={currentPage.subpage} />
            ) : loading ? (
                <LoadingProgressBar />
            ) : (
                <Fragment>
                    <Form onSubmit={handleCheckValidation} autoComplete="off">
                        <div className="scrolled-content">
                            <Container className="pt-3">
                                <Row>
                                    <Col xs={12} md={7}>
                                        <UsersListForBarmans
                                            onSetSelectedUser={(e) => {
                                                setSelectedUser(e);
                                                setBarman({ ...barman, createBarClient: false, userID: e.user_id });
                                                setDisableSubmitting(false);
                                            }}
                                            selectedUser={barman.userID}
                                            usersList={usersList}
                                            usersListData={usersListData}
                                        />
                                    </Col>
                                    <Col xs={12} md={5}>
                                        <BarmanRolesDropdown
                                            selectedRole={barman.role}
                                            onSelectRole={(e) => {
                                                setBarman({ ...barman, role: e });
                                                setDisableSubmitting(false);
                                            }}
                                        />
                                    </Col>
                                </Row>
                                {selectedUser && (
                                    <Fragment>
                                        {selectedUser.userStatus === "NEW" ? (
                                            <Row>
                                                <Col>
                                                    <Alert variant="danger">
                                                        <FontAwesomeIcon icon={faExclamationTriangle} className="me-2" />
                                                        {t("error.newUser")}
                                                    </Alert>
                                                </Col>
                                            </Row>
                                        ) : selectedUser.userStatus === "DISABLED" ? (
                                            <Row>
                                                <Col>
                                                    <Alert variant="danger">
                                                        <FontAwesomeIcon icon={faExclamationTriangle} className="me-2" />
                                                        {t("error.disabledUser")}
                                                    </Alert>
                                                </Col>
                                            </Row>
                                        ) : (
                                            <Row>
                                                {!selectedUser.userHasIdent && !selectedUser.partHasIdent && (
                                                    <Col xs={12}>
                                                        <Form.Label>
                                                            {t("barmans.assignQRCodeToBarman")} <span className="required">*</span>
                                                        </Form.Label>
                                                        {barman.identValue && <p>{barman.identValue}</p>}
                                                        <Button
                                                            variant="outline-primary"
                                                            size="lg"
                                                            className="d-block mb-3"
                                                            onClick={() => {
                                                                setDisableSubmitting(false);
                                                                setBasicAlert(null);
                                                                setQRscanner({ ...QRscanner, show: true });
                                                            }}
                                                        >
                                                            {t("ident.scanQRCode")}
                                                        </Button>
                                                    </Col>
                                                )}
                                                {!selectedUser.userHasBarClient && (
                                                    <Fragment>
                                                        <Col xs={12} className="mt-2 mb-3">
                                                            <Checkbox
                                                                id="createBarClient"
                                                                label={t("barmans.createBarClient")}
                                                                onClick={(e, data) => {
                                                                    setBarman({ ...barman, createBarClient: data.checked });
                                                                    setDisableSubmitting(false);
                                                                    if (!data.checked) setBarClientData(barClientDataStart);
                                                                }}
                                                                checked={barman.createBarClient}
                                                            />
                                                            {barman.createBarClient && !selectedUser.userHasPart && (
                                                                <Alert variant="info" className="alert-small mt-2">
                                                                    <FontAwesomeIcon icon={faCircleInfo} className="mr-2" /> {t("barClients.userHasNoPart")}
                                                                </Alert>
                                                            )}
                                                        </Col>
                                                        {barman.createBarClient && (
                                                            <Row>
                                                                <Col lg={8} xs={12}>
                                                                    <Row>
                                                                        <Col xs={12} md={5}>
                                                                            <StartingPayment
                                                                                onChangeValue={(value) => {
                                                                                    setBarClientData({ ...barClientData, startingPayment: value });
                                                                                    setDisableSubmitting(false);
                                                                                }}
                                                                                value={barClientData.startingPayment}
                                                                            />
                                                                        </Col>
                                                                        <Col xs={12} md={7}>
                                                                            <AmountLimit
                                                                                onChangeValue={(value) => {
                                                                                    setBarClientData({ ...barClientData, amountLimit: value });
                                                                                    setDisableSubmitting(false);
                                                                                }}
                                                                                disabled={barClientData.noLimit ? true : false}
                                                                                value={barClientData.amountLimit}
                                                                            />
                                                                        </Col>
                                                                        <Col xs={12}>
                                                                            <Checkbox
                                                                                className="my-3"
                                                                                id="innerAccountCheckbox"
                                                                                label={t("barClients.ifInnerAccount")}
                                                                                onClick={(e, data) => {
                                                                                    setBarClientData({ ...barClientData, ifInnerAccount: data.checked });
                                                                                    setDisableSubmitting(false);
                                                                                }}
                                                                                checked={barClientData.ifInnerAccount}
                                                                            />
                                                                        </Col>
                                                                    </Row>
                                                                </Col>
                                                                <Col lg={4} xs={12}>
                                                                    <Row>
                                                                        <Col xs={12}>
                                                                            <Checkbox
                                                                                className={barClientData.noLimit ? "mb-2 mt-2 mt-lg-0" : "mb-2 mt-lg-5 mt-2"}
                                                                                id="noLimitCheckbox"
                                                                                label={t("barClients.noLimit")}
                                                                                onClick={(e, data) => {
                                                                                    setBarClientData({ ...barClientData, noLimit: data.checked, amountLimit: data.checked ? "" : 0 });
                                                                                    setDisableSubmitting(false);
                                                                                }}
                                                                                checked={barClientData.noLimit}
                                                                            />
                                                                        </Col>
                                                                        {barClientData.noLimit && (
                                                                            <Col xs={12}>
                                                                                <Image
                                                                                    src="images/no_limit.gif"
                                                                                    alt="No no, no no no no, no no no no, No no there's no limit!"
                                                                                    fluid
                                                                                    style={{ maxHeight: "10rem" }}
                                                                                />
                                                                            </Col>
                                                                        )}
                                                                    </Row>
                                                                </Col>
                                                            </Row>
                                                        )}
                                                    </Fragment>
                                                )}
                                            </Row>
                                        )}
                                    </Fragment>
                                )}
                                <Row>
                                    <Col>
                                        <Button type="submit" variant="primary" size="xl" disabled={disableSubmitting} className="my-3">
                                            {t("add")}
                                        </Button>
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                    </Form>
                </Fragment>
            )}
        </Fragment>
    );
};

export default AddBarman;
