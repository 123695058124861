import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { Dropdown } from "semantic-ui-react";
import { Form } from "react-bootstrap";

const UsersListForBarmans = (props) => {
    const { t } = useTranslation();

    return (
        <Fragment>
            <Form.Label>
                {t("users.user")} {<span className="required">*</span>}
            </Form.Label>
            <Dropdown
                placeholder={t("searchAndSelect")}
                fluid
                search
                selection
                options={props.usersList}
                onChange={(event, { value }) => {
                    const selectedUser = props.usersListData.filter((e) => e.user_id === value);
                    console.log(selectedUser);
                    props.onSetSelectedUser(selectedUser[0]);

                    document.getElementById("usersList").classList.remove("is-invalid");
                }}
                className="mb-3"
                id="usersList"
                value={props.selectedUser}
            />
        </Fragment>
    );
};

export default UsersListForBarmans;
