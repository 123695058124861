import React, { Fragment, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { BasicContext } from "../../App";
import { Dropdown } from "semantic-ui-react";
import { Form } from "react-bootstrap";
import LoadingProgressBar from "../spinners/LoadingProgressBar";

const UsersListForBarClients = (props) => {
    const { setBasicAlert, fetchURL, activeEvent } = useContext(BasicContext);
    const { t } = useTranslation();
    const [usersList, setUsersList] = useState(null); //lista userów tylko do dropdowna
    const [usersListData, setUsersListData] = useState(null); //lista userów ze szczegółowymi danymi
    const [loading, setLoading] = useState(true);
    const [selectedUser, setSelectedUser] = useState(null);

    useEffect(() => {
        //lista userów, którzy w danym wydarzeniu nie mają jeszcze żadnego klienta
        const getUsersList = async () => {
            console.log(activeEvent);
            try {
                const response = await fetch(`${fetchURL}/users-for-bar-clients`, {
                    credentials: "include",
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },

                    body: JSON.stringify({ eventCode: activeEvent }),
                });

                if (response.status === 200) {
                    await response.json().then((fetchedData) => {
                        console.log(fetchedData);
                        if (fetchedData.status === false || fetchedData.status === "noPermission") {
                            setBasicAlert({ variant: "danger", message: t(`error.${fetchedData.code}`), fading: null });
                        } else {
                            setUsersList(fetchedData.usersList);
                            setUsersListData(fetchedData.usersListData);
                            setLoading(false);
                        }
                    });
                }
            } catch (err) {
                setBasicAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})`, fading: null });
            }
        };
        getUsersList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <Fragment>
            {loading ? (
                <LoadingProgressBar />
            ) : (
                <Fragment>
                    <Form.Label>
                        {t("barClients.person")} {props.req && <span className="required">*</span>}
                    </Form.Label>
                    <Dropdown
                        placeholder={t("searchAndSelect")}
                        fluid
                        search
                        selection
                        options={usersList}
                        onChange={(event, { value }) => {
                            const selectedUser = usersListData.filter((e) => e.userID === value);
                            console.log(selectedUser);
                            props.onSetSelectedUser(selectedUser[0]);
                            setSelectedUser(value);
                            document.getElementById("usersList").classList.remove("is-invalid");
                        }}
                        className="mb-3"
                        id="usersList"
                        value={selectedUser}
                    />
                </Fragment>
            )}
        </Fragment>
    );
};

export default UsersListForBarClients;
