import { faEdit, faToggleOff, faToggleOn } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Fragment, useContext, useEffect, useState } from "react";
import { Button, Col, Row, Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { BasicContext } from "../../../App";
import NoActiveEventAlert from "../../alerts/NoActiveEventAlert";
import ConfirmActionModal from "../../modals/ConfirmActionModal";
import EditBarItemPrices from "../../modals/EditBarItemPrices";
import NavDetails from "../../navigation/NavDetails";
import LoadingProgressBar from "../../spinners/LoadingProgressBar";
import { LoginDataContext } from "../../structure/LoggedPages";

const AssortmentList = () => {
    const { activeEvent, fetchURL, setBasicAlert } = useContext(BasicContext);
    const { currentPage } = useContext(LoginDataContext);
    const { t } = useTranslation();

    const [loading, setLoading] = useState(true);
    const [assortmentList, setAssortmentList] = useState(null);
    const [showModal, setShowModal] = useState({ show: null, item: null });
    const [fetchAssortmentList, setFetchAssortmentList] = useState(true);
    const [fetchItemActivation, setFetchItemActivation] = useState(false);

    useEffect(() => {
        const itemActivation = async () => {
            console.log("bar_item_active: ", showModal.item.bar_item_active);
            const passData = {
                activeEvent: activeEvent,
                barItemCode: showModal.item.bar_item_code,
                action: showModal.item.bar_item_active === "Y" ? "N" : showModal.item.bar_item_active === "N" ? "Y" : null,
            };
            try {
                const response = await fetch(`${fetchURL}/bar-item-activation`, {
                    credentials: "include",
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(passData),
                });

                if (response.status === 200) {
                    await response.json().then((fetchedData) => {
                        console.log(fetchedData);
                        if (fetchedData.status === false || fetchedData.status === "noPermission") {
                            setBasicAlert({ variant: "danger", message: t(`error.${fetchedData.code}`), fading: null });
                            setFetchAssortmentList(true);
                        } else {
                            setBasicAlert({ variant: "success", message: t(`success.barItem${showModal.item.bar_item_active === "Y" ? "Deactivated" : "Activated"}`), fading: 2000 });
                            setShowModal({ show: null, item: null });
                            setFetchAssortmentList(true);
                        }
                    });
                }
            } catch (err) {
                setBasicAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})`, fading: null });
            }
        };

        if (fetchItemActivation) itemActivation();
        return () => {
            setFetchItemActivation(false);
        };
    }, [fetchItemActivation]);

    useEffect(() => {
        const getAssortment = async () => {
            const passData = {
                activeEvent: activeEvent,
            };
            try {
                const response = await fetch(`${fetchURL}/bar-assortment-list`, {
                    credentials: "include",
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(passData),
                });

                if (response.status === 200) {
                    await response.json().then((fetchedData) => {
                        console.log(fetchedData);
                        if (fetchedData.status === false || fetchedData.status === "noPermission") {
                            setBasicAlert({ variant: "danger", message: t(`error.${fetchedData.code}`), fading: null });
                        } else {
                            setAssortmentList(fetchedData.list);
                            setLoading(false);
                            setFetchAssortmentList(false);
                        }
                    });
                }
            } catch (err) {
                setBasicAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})`, fading: null });
            }
        };
        if (fetchAssortmentList) getAssortment();

        return () => {
            setFetchAssortmentList(false);
        };
    }, [fetchAssortmentList]);

    console.log(fetchAssortmentList);

    return (
        <Fragment>
            <NavDetails title={`${currentPage.nav}.${currentPage.subpage}`} />

            {activeEvent === "noActiveEvent" ? (
                <NoActiveEventAlert alertTxt={currentPage.subpage} />
            ) : loading ? (
                <Row>
                    <Col className="mt-4">
                        <LoadingProgressBar />
                    </Col>
                </Row>
            ) : assortmentList.length === 0 ? (
                <Row>
                    <Col className="mt-3">
                        <p className="fs-5 text-muted">{t("barItems.emptyList")}</p>
                    </Col>
                </Row>
            ) : (
                <div className="scrolled-content">
                    <Table bordered variant="dark" className="my-3">
                        <thead>
                            <tr>
                                <th>{t("no")}</th>
                                <th>{t("barItems.name")}</th>
                                <th>{t("barItems.categories.name")}</th>
                                <th>{t("barItems.price")}</th>
                                <th>{t("barItems.innerPrice")}</th>
                                <th>{t("barItems.ingamePrice")}</th>
                                <th>{t("barItems.availability")}</th>
                                <th>{t("actions")}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {assortmentList.map((e, i) => (
                                <tr key={i}>
                                    <td>{i + 1}</td>
                                    <td>{e.bar_item_name}</td>
                                    <td>{e.bar_item_cat_name}</td>
                                    <td>
                                        {e.price} {t("currency")}
                                    </td>
                                    <td>
                                        {e.inner_price} {t("currency")}
                                    </td>
                                    <td>
                                        {e.ingame_price ? (
                                            <>
                                                {e.ingame_price} {t("caps")}
                                            </>
                                        ) : (
                                            <span className="text-muted">{t("lack")}</span>
                                        )}
                                    </td>
                                    <td>{e.bar_item_active === "Y" ? <span className="text-success">{t("available")}</span> : <span className="text-danger">{t("notAvailable")}</span>}</td>
                                    <td className="text-center">
                                        <Button size="sm" variant="outline-primary" className="mx-1" onClick={() => setShowModal({ show: "editPrices", item: e })}>
                                            <FontAwesomeIcon icon={faEdit} />
                                        </Button>

                                        <Button size="sm" variant="outline-primary" className="mx-1" onClick={() => setShowModal({ show: "itemActivation", item: e })}>
                                            <FontAwesomeIcon icon={e.bar_item_active === "Y" ? faToggleOff : faToggleOn} />
                                        </Button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </div>
            )}

            {showModal.show === "editPrices" && (
                <EditBarItemPrices
                    show
                    onClose={() => setShowModal({ show: null, item: null })}
                    item={showModal.item}
                    onCloseAndRefresh={() => {
                        setShowModal({ show: null, item: null });
                        setLoading(true);
                        setFetchAssortmentList(true);
                    }}
                />
            )}
            {showModal.show === "itemActivation" && (
                <ConfirmActionModal
                    show
                    onClose={() => setShowModal({ show: null, item: null })}
                    modalTxt={
                        <>
                            {t(`barItems.itemActivationTxt${showModal.item.bar_item_active === "Y" ? "1" : "2"}`)} <b>{showModal.item.bar_item_name}</b>?
                        </>
                    }
                    onProceed={() => {
                        setLoading(true);
                        setFetchItemActivation(true);
                    }}
                />
            )}
        </Fragment>
    );
};

export default AssortmentList;
