import { faExclamationCircle, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Fragment, useContext, useEffect, useState } from "react";
import { Alert, Button, Col, Container, Form, FormControl, InputGroup, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { BasicContext } from "../../../App";
import TextareaForm from "../../forms/TextareaForm";
import PermissionModal from "../../modals/PermissionModal";
import NavDetails from "../../navigation/NavDetails";
import LoadingProgressBar from "../../spinners/LoadingProgressBar";
import { LoginDataContext } from "../../structure/LoggedPages";
import { BarClientContext } from "./BarClients";
import ChooseBarClient from "./ChooseBarClient";

const AddPayment = () => {
    const { setBasicAlert, fetchURL, QRData, activeEvent, setQRData, setQRscanner } = useContext(BasicContext);
    const { currentPage, setLoggedUserQR, loggedUserQR } = useContext(LoginDataContext);
    const { t } = useTranslation();
    const { loadingBarClientData, getBarClient, barClientData, setBarClientData, setGetBarClient } = useContext(BarClientContext);

    const [amount, setAmount] = useState("");
    const [comment, setComment] = useState("");
    const [disableSubmitting, setDisableSubmitting] = useState(true);
    const [insertPayment, setInsertPayment] = useState(false);

    const [showPermissionModal, setShowPermissionModal] = useState("MANAG");
    const [permissionAllowed, setPermissionAllowed] = useState(false);

    useEffect(() => {
        setLoggedUserQR(null);
    }, []);

    useEffect(() => {
        const addPayment = async () => {
            const passData = {
                partID: barClientData.part_id,
                amount: amount,
                comment: comment,
                loggedUserQR: loggedUserQR,
            };
            try {
                const response = await fetch(`${fetchURL}/insert-new-payment`, {
                    credentials: "include",
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(passData),
                });

                if (response.status === 200) {
                    await response.json().then((fetchedData) => {
                        console.log(fetchedData);
                        if (fetchedData.status === false || fetchedData.status === "noPermission") {
                            setBasicAlert({ variant: "danger", message: t(`error.${fetchedData.code}`), fading: null });
                            setDisableSubmitting(false);
                        } else {
                            setBasicAlert({ variant: "success", message: t(`success.addNewPayment`), fading: 2000 });
                            setDisableSubmitting(true);
                            setAmount("");
                            setComment("");
                            setBarClientData(null);
                            setGetBarClient({ qrCode: null, barClientID: null });
                        }
                    });
                }
            } catch (err) {
                setBasicAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})`, fading: null });
            }
        };
        if (insertPayment) addPayment();

        return () => {
            setInsertPayment(false);
        };
    }, [insertPayment]);

    const handleCheckValidation = (e) => {
        console.log("add");
        e.preventDefault();
        setBasicAlert({ variant: "info", message: t("info.checkingForm") });
        const errorMsg = [],
            regExpWhiteSpace = /^\s*$/;
        if (!amount || amount === 0 || regExpWhiteSpace.test(amount)) {
            errorMsg.push(t("error.paymentAmountInvalid"));
            document.getElementById("paymentAmount").classList.add("is-invalid");
        }
        if (comment) {
            if (regExpWhiteSpace.test(comment)) {
                errorMsg.push(t("error.paymentCommentInvalid"));
                document.getElementById("paymentComment").classList.add("is-invalid");
            } else if (comment.length > 255) {
                errorMsg.push(t("error.paymentCommentTooLong"));
                document.getElementById("paymentComment").classList.add("is-invalid");
            }
        }

        if (errorMsg.length > 0) {
            setBasicAlert({
                variant: "danger",
                message: (
                    <Fragment>
                        <p className="mb-1">{t("error.errorsInForm")}</p>
                        <ul className="mb-1">
                            {errorMsg.map((e, i) => (
                                <Fragment key={i}>
                                    <li>{e}</li>
                                </Fragment>
                            ))}
                        </ul>
                    </Fragment>
                ),
                fading: null,
            });
        } else {
            setDisableSubmitting(true);
            setBasicAlert({ variant: "info", message: t("info.addingPayment") });
            setInsertPayment(true);
        }
    };

    useEffect(() => {
        const checkPermission = async () => {
            try {
                const response = await fetch(`${fetchURL}/check-permission`, {
                    credentials: "include",
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        qrCode: QRData,
                        type: showPermissionModal,
                        activeEvent: activeEvent,
                    }),
                });

                if (response.status === 200) {
                    await response.json().then((fetchedData) => {
                        console.log(fetchedData);
                        if (fetchedData.status === false || fetchedData.status === "noPermission") {
                            setBasicAlert({ variant: "danger", message: t(`error.${fetchedData.code}`), fading: null });
                        } else {
                            setLoggedUserQR(fetchedData.userID);
                            setShowPermissionModal(null);
                            setPermissionAllowed(true);
                        }
                    });
                }
            } catch (err) {
                setBasicAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})`, fading: null });
            }
        };

        if (QRData && !permissionAllowed) checkPermission();
        else if (QRData && permissionAllowed) setGetBarClient({ qrCode: QRData, barClientID: null });

        return () => {
            setQRData(null);
            setQRscanner(false);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [QRData]);

    console.log(getBarClient);

    return (
        <Fragment>
            <NavDetails title={`${currentPage.nav}.${currentPage.subpage}`} />
            {showPermissionModal && <PermissionModal onClose={() => setShowPermissionModal(null)} option={showPermissionModal} show />}
            {permissionAllowed && (
                <div className="scrolled-content">
                    <Container>
                        <ChooseBarClient
                            barClientID={getBarClient.barClientID}
                            onSelectBarClient={(v) => {
                                setGetBarClient({ qrCode: null, barClientID: v });
                            }}
                            activeEvent={activeEvent}
                        />

                        {loadingBarClientData ? (
                            <Row>
                                <Col>
                                    <LoadingProgressBar />
                                </Col>
                            </Row>
                        ) : (
                            barClientData && (
                                <Fragment>
                                    {barClientData.bar_client_stat_code === "INACTIVE" ? (
                                        <Row>
                                            <Col xs={12} className="mb-3">
                                                <Alert variant="info">
                                                    <FontAwesomeIcon icon={faInfoCircle} className="me-2" />
                                                    {t("barClients.barClientBillSettled")}
                                                </Alert>
                                            </Col>
                                        </Row>
                                    ) : barClientData.bar_client_stat_code === "BANNED" ? (
                                        <Row>
                                            <Col xs={12} className="mb-3">
                                                <Alert variant="danger">
                                                    <FontAwesomeIcon icon={faExclamationCircle} className="me-2" />
                                                    {t("barClients.barClientNotActiveTxt1")}
                                                    {t(`barClients.statuses.${barClientData.bar_client_stat_code}`)}.
                                                </Alert>
                                            </Col>
                                        </Row>
                                    ) : (
                                        <Form onSubmit={handleCheckValidation} autoComplete="off">
                                            <Row>
                                                <Col xs={12}>
                                                    <p className="my-3 fs-5">
                                                        {t("barClients.addPaymentTo")}
                                                        <b>{barClientData.participant_fullname}</b>.
                                                    </p>
                                                </Col>
                                                <Col xs={12} md={6} lg={5}>
                                                    <Form.Label>
                                                        {t("barClients.paymentAmountLabel")} <span className="required">*</span>
                                                    </Form.Label>
                                                    <InputGroup className="mb-3">
                                                        <FormControl
                                                            placeholder={t("barClients.paymentAmountPlaceholder")}
                                                            aria-label="paymentAmount"
                                                            id="paymentAmount"
                                                            value={amount}
                                                            onChange={(e) => {
                                                                setAmount(e.target.value);
                                                                setDisableSubmitting(false);
                                                                document.getElementById("paymentAmount").classList.remove("is-invalid");
                                                            }}
                                                            name="paymentAmount"
                                                            aria-describedby="paymentAmountAddon"
                                                            size="lg"
                                                        />
                                                        <InputGroup.Text id="paymentAmountAddon" className="text-uppercase">
                                                            {t("currency")}
                                                        </InputGroup.Text>
                                                    </InputGroup>
                                                </Col>
                                                <Col xs={12} md={12} lg={7}>
                                                    <TextareaForm
                                                        value={comment}
                                                        onChangeValue={(e) => {
                                                            setComment(e.target.value);
                                                            setDisableSubmitting(false);
                                                            document.getElementById("paymentComment").classList.remove("is-invalid");
                                                        }}
                                                        labelTxt={t("barClients.paymentCommentLabel")}
                                                        name="paymentComment"
                                                        placeholderTxt={t("barClients.paymentCommentPlaceholder")}
                                                        id="paymentComment"
                                                    />
                                                </Col>

                                                <Col xs={12}>
                                                    <Button variant="primary" type="submit" size="xl" disabled={disableSubmitting}>
                                                        {t("add")}
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </Form>
                                    )}
                                </Fragment>
                            )
                        )}
                    </Container>
                </div>
            )}
        </Fragment>
    );
};

export default AddPayment;
