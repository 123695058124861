import React, { useContext, useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { BasicContext } from "../../../App";

const LoginByQRCode = (props) => {
    const { setBasicAlert, QRscanner, setQRscanner, QRData, fetchURL, moduleName, setQRData, setCheckActiveEvent, setSessionAlert } = useContext(BasicContext);
    const { t } = useTranslation();
    const [doFetch, setDoFetch] = useState(false);

    useEffect(() => {
        const handleLogin = async () => {
            setSessionAlert(null);
            setBasicAlert({ variant: "info", message: t("info.logging") });
            try {
                const response = await fetch(`${fetchURL}/login`, {
                    credentials: "include",
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        loginData: {
                            email: null,
                            password: null,
                            qrCode: QRData,
                        },
                        moduleName: moduleName,
                    }),
                });

                if (response.status === 200) {
                    await response.json().then((fetchedData) => {
                        //console.log(fetchedData);
                        if (fetchedData.status === false || fetchedData.status === "noPermission") {
                            setBasicAlert({ variant: "danger", message: t(`error.${fetchedData.code}`), fading: null });
                        } else if (fetchedData.status === "inactive profile" || fetchedData.status === "reset pass") {
                            setBasicAlert({
                                variant: "danger",
                                message: t(`error.${fetchedData.code}`),
                                contactBtn: {
                                    mailAddress: "",
                                    mailTitle:
                                        fetchedData.status === "inactive profile" ? t("user.inactiveAccount") : fetchedData.status === "reset pass" ? t("user.resetPass") : "",
                                    mailSender: "",
                                },
                            });
                        } else {
                            setCheckActiveEvent({ userPrivileges: fetchedData.userPrivilages, cookieExpDate: fetchedData.cookieExpDate });
                            setBasicAlert({ variant: "success", message: t(`success.login`), fading: 800 });
                        }

                        setQRData(null);
                        setQRscanner(false);
                    });
                }
            } catch (err) {
                setBasicAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})`, fading: null });
            }
        };
        if (doFetch && QRData) handleLogin();

        return () => {
            setDoFetch(false);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [doFetch]);

    useEffect(() => {
        if (QRData) {
            setBasicAlert({ variant: "info", message: t("info.scanning"), fading: null });
            setDoFetch(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [QRData]);
    return (
        <Row>
            <Col xs={12} className="text-center">
                <Button
                    variant="primary"
                    onClick={() => {
                        setBasicAlert(null);
                        setQRscanner({ ...QRscanner, show: true });
                    }}
                    className="btn-xl my-4"
                >
                    {t("ident.loginByQRCode")}
                </Button>
            </Col>
            <Col xs={12} className="my-4 text-center">
                <p className="h5">{t("or")}</p>
            </Col>
            <Col xs={12} className="text-center">
                <Button variant="outline-primary" onClick={() => props.onSetSubPage("loginForm")}>
                    {t("form.loginByForm")}
                </Button>
            </Col>
        </Row>
    );
};

export default LoginByQRCode;
