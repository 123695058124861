import React, { Fragment, useState } from "react";
import { Alert, Col, Row, Image, Form, Button } from "react-bootstrap";
import $ from "jquery";
import { useTranslation } from "react-i18next";
import ShowIconsListModal from "../modals/ShowIconsListModal";

const ChooseTileIcon = (props) => {
    const { t } = useTranslation();
    const [invalidFile, setInvalidFile] = useState(null);
    const [showIconsList, setShowIconsList] = useState(false);

    const handleUploadNewFile = (event) => {
        console.log(event);
        const newFile = event.target.files[0];

        if (newFile) {
            /* 16777216 - 16 MB, 2097152 - 2MB */
            if (newFile.type !== "image/svg+xml" && newFile.type !== "image/svg" && newFile.type !== "image/png") setInvalidFile("invalidFileFormat");
            else if (newFile.size > 2097152) setInvalidFile("tooBigFile");
            else {
                setInvalidFile(null);
                var reader = new FileReader();
                reader.onload = function(e) {
                    $(`#${props.id}`).attr("src", e.target.result);
                    $(`#${props.id}`).attr("alt", "newAvatar");
                };
                reader.readAsDataURL(newFile);
                newFile.blobID = null;
                console.log(newFile);
                props.onChooseIcon(newFile);
            }
        }
    };

    console.log(props.id);

    return (
        <Fragment>
            <Col xs={12}>
                <Form.Label>{t("barItemCategories.icon")}</Form.Label>
                <p className="text-small mb-3">{t("barItemCategories.iconHelper")}</p>
            </Col>
            {props.icon && props.icon !== "noFile" && (
                <Col xs={12}>
                    <Row>
                        <Col xs={6} lg={3} md={4}>
                            {props.icon.blobID ? (
                                <img src={`data:${props.icon.type};base64,${props.icon.data}`} alt={props.icon.name} className="img-fluid mb-3 icon-tile" />
                            ) : (
                                <Image src="" alt="cat_icon" id={props.id} className="img-fluid mb-3 icon-tile" />
                            )}
                        </Col>
                    </Row>
                </Col>
            )}
            <Col xs={12}>
                <Button variant="outline-secondary" onClick={() => setShowIconsList(true)} className="mb-3 me-3 d-inline-block">
                    {t("barItems.chooseExistingIcon")}
                </Button>
                <div className="d-inline-block">
                    <div className="choose-file">
                        <input
                            type="file"
                            className="choose-file-input btn-outline-secondary"
                            id={`${props.id}ChooseFile`}
                            name="icon"
                            onChange={handleUploadNewFile}
                            accept="image/x-png,image/svg+xml"
                        />
                        <label className="choose-file-label btn btn-outline-secondary align-bottom" htmlFor={`${props.id}ChooseFile`} aria-describedby="chooseFileAddon">
                            {t("barItems.chooseNewIcon")}
                        </label>
                    </div>
                </div>
            </Col>

            {invalidFile && (
                <Col xs={12} className="text-center">
                    <Alert variant="danger" className="alert-small">
                        {t(`error.${invalidFile}`)}
                    </Alert>
                </Col>
            )}

            {showIconsList && (
                <ShowIconsListModal
                    show
                    onClose={() => setShowIconsList(false)}
                    onChooseIcon={(icon) => {
                        setShowIconsList(false);
                        props.onChooseIcon(icon);
                    }}
                />
            )}
        </Fragment>
    );
};

export default ChooseTileIcon;
