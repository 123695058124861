import React, { Fragment, useContext } from "react";
import { QrReader } from "react-qr-reader";
import { BasicContext } from "../../App";
import { useTranslation } from "react-i18next";
import { Button } from "react-bootstrap";

const ShowQRCode = () => {
    const { QRscanner, setQRscanner, setQRData } = useContext(BasicContext);

    const { t } = useTranslation();

    document.addEventListener("keydown", (event) => {
        if (event.keyCode === 27) setQRscanner({ ...QRscanner, show: false });
    });

    return (
        <Fragment>
            <QrReader
                onResult={(result, error) => {
                    if (!!result) {
                        console.log(result.text);
                        setQRData(result.text);
                    }

                    if (!!error) {
                        //console.log(error);
                    }
                }}
                className="qrReader"
                scanDelay={300}
                constraints={{ facingMode: "environment" }}
            />
            <Button onClick={() => setQRscanner(false)} variant="primary">
                {t("close")}
            </Button>
        </Fragment>
    );
};

export default ShowQRCode;
