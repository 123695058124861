import { faEdit, faEraser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Fragment, useContext, useEffect, useState } from "react";
import { Button, Col, Container, Form, InputGroup, Row, Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Dropdown } from "semantic-ui-react";
import { BasicContext } from "../../../App";
import PermissionModal from "../../modals/PermissionModal";
import LoadingProgressBar from "../../spinners/LoadingProgressBar";
import { LoginDataContext } from "../../structure/LoggedPages";

const BarmansList = () => {
    const { setBasicAlert, fetchURL, activeEvent, QRData, setQRscanner, setQRData } = useContext(BasicContext);
    const { setLoggedUserQR, setNextPage } = useContext(LoginDataContext);

    const { t } = useTranslation();
    const [barmansList, setBarmansList] = useState(null);
    const [originalBarmansList, setOriginalBarmansList] = useState(null);
    const [loading, setLoading] = useState(true);
    const [eventsList, setEventsList] = useState(null);
    const [searchedPhrase, setSearchedPhrase] = useState("");
    const [selectedEvent, setSelectedEvent] = useState(activeEvent !== "noActiveEvent" ? activeEvent : "all");
    const [showPermissionModal, setShowPermissionModal] = useState(null);
    const [goToPage, setGoToPage] = useState(null);

    useEffect(() => {
        const getEventsList = async () => {
            try {
                const response = await fetch(`${fetchURL}/all-events-list`, {
                    credentials: "include",
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({}),
                });

                if (response.status === 200) {
                    await response.json().then((fetchedData) => {
                        console.log(fetchedData);
                        if (fetchedData.status === false || fetchedData.status === "noPermission") {
                            setBasicAlert({ variant: "danger", message: t(`error.${fetchedData.code}`), fading: null });
                        } else {
                            const list = fetchedData.events;
                            list.unshift({
                                key: 0,
                                value: "all",
                                text: t("all"),
                            });
                            setEventsList(list);
                        }
                    });
                }
            } catch (err) {
                setBasicAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})`, fading: null });
            }
        };
        const getBarmansList = async () => {
            try {
                const response = await fetch(`${fetchURL}/barmans-list`, {
                    credentials: "include",
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({}),
                });

                if (response.status === 200) {
                    await response.json().then((fetchedData) => {
                        console.log(fetchedData);
                        if (fetchedData.status === false || fetchedData.status === "noPermission") {
                            setBasicAlert({ variant: "danger", message: t(`error.${fetchedData.code}`), fading: null });
                        } else {
                            setBarmansList(fetchedData.list);
                            setOriginalBarmansList(fetchedData.list);
                            setLoading(false);
                        }
                    });
                }
            } catch (err) {
                setBasicAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})`, fading: null });
            }
        };

        getBarmansList();
        getEventsList();
        setLoggedUserQR(null);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleSearch = (e) => {
        const phrase = e.target.value.toLowerCase();
        //setSearchedPhrase(phrase);
        console.log(phrase);
        if (phrase !== "") {
            let filteredBarmansList = originalBarmansList;
            console.log(filteredBarmansList);
            filteredBarmansList = filteredBarmansList.filter(
                (el) =>
                    el.user_fullname
                        .toString()
                        .toLowerCase()
                        .includes(phrase) ||
                    el.user_roles_list
                        .toString()
                        .toLowerCase()
                        .includes(phrase)
            );
            setBarmansList(filteredBarmansList);
        } else setBarmansList(originalBarmansList);

        setSearchedPhrase(phrase);
    };

    const filterList = (e) => {
        if (selectedEvent === "all" || e.event_code === selectedEvent || !e.event_code) return true;
        else return false;
    };

    let filteredList = [];
    if (barmansList) {
        //wyfiltrowana lista uczestników o wydarzenia, status wpłat i status uczestnika
        filteredList = barmansList.filter(filterList);
    }

    useEffect(() => {
        const checkPermission = async () => {
            try {
                const response = await fetch(`${fetchURL}/check-permission`, {
                    credentials: "include",
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        qrCode: QRData,
                        type: showPermissionModal,
                        activeEvent: activeEvent,
                    }),
                });

                if (response.status === 200) {
                    await response.json().then((fetchedData) => {
                        console.log(fetchedData);
                        if (fetchedData.status === false || fetchedData.status === "noPermission") {
                            setBasicAlert({ variant: "danger", message: t(`error.${fetchedData.code}`), fading: null });
                        } else {
                            setLoggedUserQR(fetchedData.userID);
                            setShowPermissionModal(null);
                            console.log(goToPage);
                            setNextPage(goToPage);
                        }
                    });
                }
            } catch (err) {
                setBasicAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})`, fading: null });
            }
        };

        if (QRData && goToPage) checkPermission();

        return () => {
            setQRData(null);
            setQRscanner(false);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [QRData]);

    return (
        <Fragment>
            {showPermissionModal && <PermissionModal onClose={() => setShowPermissionModal(null)} option={showPermissionModal} show onlyClose />}

            <Container className="py-3 bar-nav-details" fluid>
                <Row>
                    <Col lg={4} xs={12} md={5}>
                        <Form.Label>{t("form.searching")}</Form.Label>
                        <InputGroup className="mb-3">
                            <Form.Control
                                type="text"
                                placeholder={t("form.search")}
                                onChange={handleSearch}
                                value={searchedPhrase}
                                aria-label="search input"
                                aria-describedby="search-input"
                                size="lg"
                            />
                            <Button
                                id="search-input"
                                disabled={!searchedPhrase}
                                variant="outline-primary"
                                onClick={() => {
                                    setSearchedPhrase("");
                                    setBarmansList(originalBarmansList);
                                }}
                            >
                                <FontAwesomeIcon icon={faEraser} />
                            </Button>
                        </InputGroup>
                    </Col>
                    <Col lg={4} xs={12} md={4}>
                        <Form.Label>{t("barmans.event")}</Form.Label>
                        {eventsList ? (
                            <Dropdown
                                placeholder={t("select")}
                                fluid
                                selection
                                options={eventsList}
                                onChange={(event, { value }) => {
                                    setSelectedEvent(value);
                                }}
                                className="mb-3"
                                id="usersList"
                                value={selectedEvent}
                            />
                        ) : (
                            <LoadingProgressBar />
                        )}
                    </Col>
                    <Col className="text-end" lg={4} md={3} xs={12}>
                        <Button
                            variant="outline-primary"
                            className="mt-5 mb-0"
                            onClick={() => {
                                setLoggedUserQR(null);
                                setShowPermissionModal("ADMIN");
                                setGoToPage({ nav: "barmans", subpage: "addBarman", params: "" });
                            }}
                        >
                            {t("barmans.addBarman")}
                        </Button>
                    </Col>
                </Row>
            </Container>
            <div className="scrolled-content table">
                <Container className="pt-3" fluid>
                    {loading ? (
                        <Container>
                            <Row>
                                <Col>
                                    <LoadingProgressBar />
                                </Col>
                            </Row>
                        </Container>
                    ) : (
                        <Row>
                            <Col>
                                <Table bordered hover className="table-list mb-0">
                                    <thead>
                                        <tr>
                                            <th>{t("n.o.")}</th>
                                            <th>{t("barmans.name")}</th>
                                            <th>{t("barmans.roles")}</th>
                                            <th>{t("barmans.phone")}</th>
                                            <th>{t("barmans.assignedIdent")}</th>
                                            <th>{t("actions")}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {filteredList.map((e, i) => (
                                            <tr key={i}>
                                                <td>{i + 1}</td>
                                                <td>{e.user_fullname}</td>
                                                <td>{e.user_roles_list}</td>
                                                <td>{e.user_phone ? e.user_phone : <span className="text-muted">{t("lack")}</span>}</td>
                                                <td>{e.user_qr_code ? t("yes") : t("no")}</td>
                                                <td className="text-center">
                                                    <Button
                                                        variant="outline-primary"
                                                        onClick={() => {
                                                            setLoggedUserQR(null);
                                                            setShowPermissionModal("ADMIN");
                                                            setGoToPage({ nav: "barmans", subpage: "editBarman", params: { userID: e.user_id } });
                                                        }}
                                                        size="sm"
                                                    >
                                                        <FontAwesomeIcon icon={faEdit} />
                                                    </Button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>
                    )}
                </Container>
            </div>
        </Fragment>
    );
};

export default BarmansList;
