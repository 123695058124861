import React, { Fragment, useContext } from "react";
import { Nav, Navbar, Container, NavDropdown, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { BasicContext } from "../../App";
import BtnNav from "./BtnNav";
import NavDropdownItem from "./NavDropdownItem";
import { LoginDataContext } from "../structure/LoggedPages";

const MainNavbar = () => {
    const { setLogout } = useContext(BasicContext);
    const { currentPage } = useContext(LoginDataContext);
    const { t } = useTranslation();
    let activePage = !currentPage ? "sell" : currentPage.nav;

    return (
        <Fragment>
            <Navbar id="barNavbar" expand="md" variant="dark">
                <Container fluid>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="me-auto">
                            <BtnNav navName="sell" pageName="sellMain" title={t("barNavbar.sell")} activePage={activePage} />
                            <NavDropdown title={t("barNavbar.barClients")} id="basic-nav-dropdown" className={activePage === "barClients" ? "active" : ""}>
                                <NavDropdownItem navName="barClients" pageName="addBarClient" title={t("barNavbar.addBarClient")} />
                                <NavDropdown.Divider />
                                <NavDropdownItem navName="barClients" pageName="barClientDetails" title={t("barNavbar.barClientDetails")} />
                                <NavDropdownItem navName="barClients" pageName="addPayment" title={t("barNavbar.addPayment")} />
                                <NavDropdownItem navName="barClients" pageName="settleTick" title={t("barNavbar.settleTick")} />
                            </NavDropdown>
                            {/* <NavDropdown title={t("barNavbar.duties")} id="basic-nav-dropdown" className={activePage === "duties" ? "active" : ""}>
                                <NavDropdownItem navName="duties" pageName="showSchedule" title={t("barNavbar.showSchedule")} />
                                <NavDropdownItem navName="duties" pageName="addBarmanToDutie" title={t("barNavbar.addBarmanToDutie")} />
                            </NavDropdown> */}
                            {/*  <BtnNav navName="duties" pageName="dutiesTable" title={t("barNavbar.duties")} activePage={activePage} /> */}
                            <BtnNav navName="barmans" pageName="barmansList" title={t("barNavbar.barmans")} activePage={activePage} />
                            <NavDropdown title={t("barNavbar.adminPanel")} id="basic-nav-dropdown" className={activePage === "adminPanel" ? "active" : ""}>
                                <NavDropdownItem navName="adminPanel" pageName="addBarItem" title={t("barNavbar.addBarItem")} />
                                <NavDropdownItem navName="adminPanel" pageName="addBarItemCategory" title={t("barNavbar.addBarItemCategory")} />
                                <NavDropdownItem navName="adminPanel" pageName="assortmentList" title={t("barNavbar.assortmentList")} />
                            </NavDropdown>
                        </Nav>
                        <Nav>
                            <Button variant="link" onClick={() => setLogout(true)}>
                                {t("barNavbar.logout")}
                            </Button>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
            <div id="borderNavbar"></div>
        </Fragment>
    );
};

export default MainNavbar;
