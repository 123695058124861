import { faCheck, faComment, faEdit, faQuestion } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Fragment, useContext, useEffect, useState } from "react";
import { Button, Col, Container, Form, OverlayTrigger, Row, Table, Tooltip } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { BasicContext } from "../../../App";
import NavDetails from "../../navigation/NavDetails";
import LoadingProgressBar from "../../spinners/LoadingProgressBar";
import { LoginDataContext } from "../../structure/LoggedPages";
import SelectPartsToDutyModal from "../../modals/SelectPartsToDutyModal";
import ScheduleConfirmModal from "../../modals/ScheduleConfirmModal";

const EditSchedule = () => {
    const { setBasicAlert, fetchURL, activeEvent } = useContext(BasicContext);
    const { t } = useTranslation();
    const { currentPage, loggedUserQR, setNextPage } = useContext(LoginDataContext);

    const [partsAvailability, setPartsAvailability] = useState(null);
    const [periods, setPeriods] = useState(null);
    const [loading, setLoading] = useState(true);
    const [selectPartsToDutyModal, setSelectPartsToDutyModal] = useState(null);
    const [disableSubmitting, setDisableSubmitting] = useState(true);
    const [showConfirmModal, setShowConfirmModal] = useState(null);
    const [fetchUpdateSchedule, setFetchUpdateSchedule] = useState(false);

    useEffect(() => {
        if (periods && loading) {
            const listAval = [...partsAvailability];

            listAval.forEach((e, i) => {
                let countDuties = 0;
                //console.log(e);
                const partID = e.part_id;
                periods.forEach((p) => {
                    p.participants.forEach((part) => {
                        if (part.partID === partID) {
                            //jest dyżur
                            countDuties++;
                        }
                    });
                });
                //console.log(countDuties);
                listAval[i].usedDuties = countDuties;
            });
            console.log(listAval);
            setPartsAvailability(listAval);
            setLoading(false);
        }
    }, [periods]);

    useEffect(() => {
        const getScheduleData = async () => {
            const passData = {
                activeEvent: activeEvent,
            };
            try {
                const response = await fetch(`${fetchURL}/get-schedule-data`, {
                    credentials: "include",
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(passData),
                });

                if (response.status === 200) {
                    await response.json().then((fetchedData) => {
                        console.log(fetchedData);
                        if (fetchedData.status === false || fetchedData.status === "noPermission") setBasicAlert({ variant: "danger", message: t(`error.${fetchedData.code}`), fading: null });
                        else setPeriods(fetchedData.periods);
                    });
                }
            } catch (err) {
                setBasicAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})`, fading: null });
            }
        };

        const getPartsAvailability = async () => {
            const passData = {
                activeEvent: activeEvent,
            };
            try {
                const response = await fetch(`${fetchURL}/get-parts-availability`, {
                    credentials: "include",
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(passData),
                });
                if (response.status === 200) {
                    await response.json().then((fetchedData) => {
                        console.log(fetchedData);
                        if (fetchedData.status === false || fetchedData.status === "noPermission") setBasicAlert({ variant: "danger", message: t(`error.${fetchedData.code}`), fading: null });
                        else {
                            setPartsAvailability(fetchedData.partList);
                            getScheduleData();
                        }
                    });
                }
            } catch (err) {
                setBasicAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})` });
            }
        };

        getPartsAvailability();
    }, []);

    const handleSelectParts = (parts) => {
        const list = [...periods],
            listAval = [...partsAvailability],
            selectedParts = [];
        parts.forEach((e) => {
            if (e) {
                selectedParts.push(e);
            }
        });
        list[selectPartsToDutyModal.index].participants = selectedParts;

        //zliczanie ile razy uczestnikowi został przydzielony dyżur
        listAval.forEach((e, i) => {
            let countDuties = 0;
            //console.log(e);
            const partID = e.part_id;
            list.forEach((p) => {
                p.participants.forEach((part) => {
                    if (part.partID === partID) {
                        //jest dyżur
                        countDuties++;
                    }
                });
            });
            //console.log(countDuties);
            listAval[i].usedDuties = countDuties;
        });
        console.log(listAval);

        setPeriods(list);
        setPartsAvailability(listAval);
        setDisableSubmitting(false);
        setSelectPartsToDutyModal(null);
    };

    const handleCheckValidation = (e) => {
        e.preventDefault();
        setBasicAlert(null);
        let errorMsg = [];
        console.log(periods);

        let emptySchedule = true;

        for (let i = 0; i < periods.length; i++) {
            const e = periods[i];
            if (e.participants.length > 0) {
                emptySchedule = false;
                break;
            }
        }

        if (emptySchedule) errorMsg.push(t("error.emptySchedule"));

        if (errorMsg.length > 0) {
            setBasicAlert({
                variant: "danger",
                message: (
                    <Fragment>
                        <p className="mb-1">{t("error.errorsInForm")}</p>
                        <ul className="mb-1">
                            {errorMsg.map((e, i) => (
                                <Fragment key={i}>
                                    <li>{e}</li>
                                </Fragment>
                            ))}
                        </ul>
                    </Fragment>
                ),
                fading: null,
            });
        } else {
            let partsWithNoDuties = [],
                partsWithToMuchDuties = [];
            partsAvailability.forEach((e) => {
                if (e.usedDuties === 0) partsWithNoDuties.push(e.partName);
                else if (typeof e.numberOfDuties !== "string" && e.usedDuties > e.numberOfDuties) partsWithToMuchDuties.push(e.partName);
            });
            //console.log(partsListWithNoDuties);
            if (partsWithNoDuties.length > 0 || partsWithToMuchDuties.length > 0) {
                setShowConfirmModal({ partsWithNoDuties: partsWithNoDuties, partsWithToMuchDuties: partsWithToMuchDuties });
            } else {
                setBasicAlert({ variant: "info", message: t("info.assigningPartsToDuties") });
                setFetchUpdateSchedule(true);
            }
        }
    };

    useEffect(() => {
        const updateSchedule = async () => {
            const passData = {
                partsAvailability: partsAvailability,
                loggedUserQR: loggedUserQR,
                periods: periods,
            };
            console.log(passData);
            try {
                const response = await fetch(`${fetchURL}/update-schedule`, {
                    credentials: "include",
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(passData),
                });
                if (response.status === 200) {
                    await response.json().then((fetchedData) => {
                        console.log(fetchedData);
                        if (fetchedData.status === false || fetchedData.status === "noPermission") setBasicAlert({ variant: "danger", message: t(`error.${fetchedData.code}`), fading: null });
                        else {
                            setBasicAlert({ variant: "success", message: t(`success.scheduleEdited`), fading: 2000 });
                            setNextPage({ nav: "duties", subpage: "dutiesTable", params: "" });
                        }
                    });
                }
            } catch (err) {
                setBasicAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})` });
            }
        };

        if (fetchUpdateSchedule) updateSchedule();

        return () => {
            setFetchUpdateSchedule(false);
        };
    }, [fetchUpdateSchedule]);

    return (
        <Fragment>
            <NavDetails backBtn title={`${currentPage.nav}.${currentPage.subpage}`} />
            <Form autoComplete="off" onSubmit={handleCheckValidation}>
                <div className="scrolled-content">
                    {loading ? (
                        <Container className="pt-3">
                            <Row>
                                <Col>
                                    <LoadingProgressBar />
                                </Col>
                            </Row>
                        </Container>
                    ) : (
                        <Container className="pt-3">
                            <Row>
                                <Col xs={12}>
                                    <p className="h5 mb-3 mt-2">{t(`duties.partsList`)}:</p>
                                </Col>
                                {partsAvailability.map((e, i) => (
                                    <Col xs={6} md={4} lg={4} key={i}>
                                        {e.partName} <span className={typeof e.numberOfDuties !== "string" && e.usedDuties > e.numberOfDuties ? "text-danger" : ""}>{e.usedDuties}</span>/
                                        {e.numberOfDuties}
                                        {e.comment && (
                                            <OverlayTrigger key="top" placement="top" overlay={<Tooltip id={`tooltip-top`}>{e.comment}</Tooltip>}>
                                                <Button variant="link" className="ms-2">
                                                    <FontAwesomeIcon icon={faComment} />
                                                </Button>
                                            </OverlayTrigger>
                                        )}
                                    </Col>
                                ))}
                            </Row>
                            <Row>
                                <Col className="mt-4">
                                    <Table responsive bordered className="table-details t-bordered">
                                        <tbody>
                                            {periods.map((e, i) => (
                                                <tr key={i}>
                                                    <td className="text-center" style={{ width: "20%" }}>
                                                        {e.duty_period_label} {e.duty_period_date}
                                                        <br />
                                                        {e.duty_period_start} - {e.duty_period_end}
                                                    </td>
                                                    <td className={e.is_larp === "Y" ? "larp" : ""}>
                                                        {e.participants.length === 0 ? (
                                                            <span className="text-muted">{t("lack")}</span>
                                                        ) : (
                                                            e.participants.map((p, j) => (
                                                                <Fragment key={j}>
                                                                    <p className="mb-0">
                                                                        {p.partName}{" "}
                                                                        {p.partAnswer === "Y" ? (
                                                                            <span>
                                                                                <FontAwesomeIcon icon={faCheck} className="text-success" />
                                                                            </span>
                                                                        ) : p.partAnswer === "M" ? (
                                                                            <span>
                                                                                <FontAwesomeIcon icon={faQuestion} className="text-warning" />
                                                                            </span>
                                                                        ) : (
                                                                            <span></span>
                                                                        )}
                                                                    </p>
                                                                </Fragment>
                                                            ))
                                                        )}
                                                    </td>
                                                    <td style={{ width: "20px" }}>
                                                        <Button
                                                            className="w-100"
                                                            variant="outline-primary"
                                                            onClick={() => {
                                                                setSelectPartsToDutyModal({ index: i, period: e });
                                                            }}
                                                        >
                                                            <FontAwesomeIcon icon={faEdit} />
                                                        </Button>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Button type="submit" variant="primary" disabled={disableSubmitting} size="lg" className="mb-3">
                                        {t("save")}
                                    </Button>
                                </Col>
                            </Row>
                        </Container>
                    )}
                </div>
            </Form>

            {selectPartsToDutyModal && (
                <SelectPartsToDutyModal
                    onClose={() => {
                        setSelectPartsToDutyModal(null);
                        setBasicAlert(null);
                    }}
                    onSelectParts={handleSelectParts}
                    period={selectPartsToDutyModal.period}
                />
            )}

            {showConfirmModal && (
                <ScheduleConfirmModal
                    onClose={() => {
                        setShowConfirmModal(null);
                        setBasicAlert(null);
                    }}
                    partsList={showConfirmModal}
                    onConfirm={() => {
                        setBasicAlert({ variant: "info", message: t("info.assigningPartsToDuties") });
                        setFetchUpdateSchedule(true);
                    }}
                />
            )}
        </Fragment>
    );
};

export default EditSchedule;
