import React, { Fragment, useContext, useEffect, useState } from "react";
import { Button, Col, Form, FormControl, InputGroup, Modal, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { BasicContext } from "../../App";
import TextareaForm from "../forms/TextareaForm";
import { LoginDataContext } from "../structure/LoggedPages";

const AddBarClientModal = (props) => {
    const { setBasicAlert, fetchURL } = useContext(BasicContext);
    const { loggedUserQR } = useContext(LoginDataContext);
    const { t } = useTranslation();

    const [amount, setAmount] = useState("");
    const [comment, setComment] = useState("");
    const [disableSubmitting, setDisableSubmitting] = useState(true);
    const [insertPayment, setInsertPayment] = useState(false);

    useEffect(() => {
        const addPayment = async () => {
            const passData = {
                partID: props.partID,
                amount: amount,
                comment: comment,
                loggedUserQR: loggedUserQR,
            };
            try {
                const response = await fetch(`${fetchURL}/insert-new-payment`, {
                    credentials: "include",
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(passData),
                });

                if (response.status === 200) {
                    await response.json().then((fetchedData) => {
                        console.log(fetchedData);
                        if (fetchedData.status === false || fetchedData.status === "noPermission") {
                            setBasicAlert({ variant: "danger", message: t(`error.${fetchedData.code}`), fading: null });
                            setDisableSubmitting(false);
                        } else {
                            setBasicAlert({ variant: "success", message: t(`success.addNewPayment`), fading: 2000 });
                            props.onCloseAndRefresh();
                        }
                    });
                }
            } catch (err) {
                setBasicAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})`, fading: null });
            }
        };
        if (insertPayment) addPayment();

        return () => {
            setInsertPayment(false);
        };
    }, [insertPayment]);

    const handleCheckValidation = (e) => {
        console.log("add");
        e.preventDefault();
        setBasicAlert({ variant: "info", message: t("info.checkingForm") });
        const errorMsg = [],
            regExpWhiteSpace = /^\s*$/;
        if (!amount || amount === 0 || regExpWhiteSpace.test(amount)) {
            errorMsg.push(t("error.paymentAmountInvalid"));
            document.getElementById("paymentAmount").classList.add("is-invalid");
        }
        if (comment) {
            if (regExpWhiteSpace.test(comment)) {
                errorMsg.push(t("error.paymentCommentInvalid"));
                document.getElementById("paymentComment").classList.add("is-invalid");
            } else if (comment.length > 255) {
                errorMsg.push(t("error.paymentCommentTooLong"));
                document.getElementById("paymentComment").classList.add("is-invalid");
            }
        }

        if (errorMsg.length > 0) {
            setBasicAlert({
                variant: "danger",
                message: (
                    <Fragment>
                        <p className="mb-1">{t("error.errorsInForm")}</p>
                        <ul className="mb-1">
                            {errorMsg.map((e, i) => (
                                <Fragment key={i}>
                                    <li>{e}</li>
                                </Fragment>
                            ))}
                        </ul>
                    </Fragment>
                ),
                fading: null,
            });
        } else {
            setDisableSubmitting(true);
            setBasicAlert({ variant: "info", message: t("info.addingPayment") });
            setInsertPayment(true);
        }
    };

    return (
        <Fragment>
            <Modal show={props.show} onHide={props.onClose} backdrop="static" keyboard={false}>
                <Form onSubmit={handleCheckValidation} autoComplete="off">
                    <Modal.Header>
                        <Modal.Title>{t("barClients.addPayment")}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p className="my-3">
                            {t("barClients.addPaymentTo")}
                            <b>{props.barClientName}</b>.
                        </p>
                        <Row>
                            <Col xs={12}>
                                <Form.Label>
                                    {t("barClients.paymentAmountLabel")} <span className="required">*</span>
                                </Form.Label>
                                <InputGroup className="mb-3">
                                    <FormControl
                                        placeholder={t("barClients.paymentAmountPlaceholder")}
                                        aria-label="paymentAmount"
                                        id="paymentAmount"
                                        value={amount}
                                        onChange={(e) => {
                                            setAmount(e.target.value);
                                            setDisableSubmitting(false);
                                            document.getElementById("paymentAmount").classList.remove("is-invalid");
                                        }}
                                        name="paymentAmount"
                                        aria-describedby="paymentAmountAddon"
                                        size="lg"
                                    />
                                    <InputGroup.Text id="paymentAmountAddon" className="text-uppercase">
                                        {t("currency")}
                                    </InputGroup.Text>
                                </InputGroup>
                                <TextareaForm
                                    value={comment}
                                    onChangeValue={(e) => {
                                        setComment(e.target.value);
                                        setDisableSubmitting(false);
                                        document.getElementById("paymentComment").classList.remove("is-invalid");
                                    }}
                                    labelTxt={t("barClients.paymentCommentLabel")}
                                    name="paymentComment"
                                    placeholderTxt={t("barClients.paymentCommentPlaceholder")}
                                    id="paymentComment"
                                />
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="outline-secondary" type="button" size="lg" className="me-3" onClick={() => props.onClose()}>
                            {t("cancel")}
                        </Button>
                        <Button variant="primary" type="submit" size="lg" disabled={disableSubmitting}>
                            {t("add")}
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </Fragment>
    );
};

export default AddBarClientModal;
