import React, { Fragment, useContext, useState, useEffect } from "react";
import { Button, Col, Form, Row, Container } from "react-bootstrap";
import { t } from "i18next";
import { BasicContext } from "../../../App";
import TextForm from "../../forms/TextForm";
import ChooseTileIcon from "../../forms/ChooseTileIcon";
import NavDetails from "../../navigation/NavDetails";
import { LoginDataContext } from "../../structure/LoggedPages";

const AddBarItemCategory = () => {
    const { setBasicAlert, fetchURL } = useContext(BasicContext);
    const { currentPage, loggedUserQR } = useContext(LoginDataContext);
    const [category, setCategory] = useState({ code: "", name: "", priority: "", selectedIcon: false });
    const [catIcon, setCatIcon] = useState(null);
    const [disableSubmitting, setDisableSubmitting] = useState(true);
    const [checkAndAdd, setCheckAndAdd] = useState(false);

    const handleCheckValidation = (e) => {
        setBasicAlert({ variant: "info", message: t("info.checkingForm") });
        e.preventDefault();
        const errorMsg = [],
            regExpWhiteSpace = /^\s*$/,
            regNumber = /^\d+$/;

        if (!category.code) {
            errorMsg.push(t("error.emptyBarItemCategoryCode"));
            document.getElementById("barItemCatCode").classList.add("is-invalid");
        } else if (regExpWhiteSpace.test(category.code) || category.code.length > 100) {
            errorMsg.push(t("error.invalidBarItemCategoryCode"));
            document.getElementById("barItemCatCode").classList.add("is-invalid");
        }

        if (!category.name) {
            errorMsg.push(t("error.emptyBarItemCategoryName"));
            document.getElementById("barItemCatName").classList.add("is-invalid");
        } else if (regExpWhiteSpace.test(category.name) || category.name.length > 255) {
            errorMsg.push(t("error.invalidBarItemCategoryName"));
            document.getElementById("barItemCatName").classList.add("is-invalid");
        }

        if (!category.priority) {
            errorMsg.push(t("error.emptyBarItemCategoryPriority"));
            document.getElementById("barItemCatPriority").classList.add("is-invalid");
        } else if (regExpWhiteSpace.test(category.priority) || category.priority.length > 11 || !regNumber.test(category.priority)) {
            errorMsg.push(t("error.invalidBarItemCategoryPriority"));
            document.getElementById("barItemCatPriority").classList.add("is-invalid");
        }

        if (errorMsg.length > 0) {
            setBasicAlert({
                variant: "danger",
                message: (
                    <Fragment>
                        <p className="mb-1">{t("error.errorsInForm")}</p>
                        <ul className="mb-1">
                            {errorMsg.map((e, i) => (
                                <Fragment key={i}>
                                    <li>{e}</li>
                                </Fragment>
                            ))}
                        </ul>
                    </Fragment>
                ),
                fading: null,
            });
        } else {
            setDisableSubmitting(true);
            setBasicAlert({ variant: "info", message: t("info.addingNewBarItemCategory") });
            setCheckAndAdd(true);
        }
    };

    useEffect(() => {
        const insertNewCategory = async (blobID) => {
            const passData = {
                category: category,
                blobID: blobID,
                loggedUserQR: loggedUserQR,
            };
            try {
                const response = await fetch(`${fetchURL}/insert-new-category`, {
                    credentials: "include",
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(passData),
                });

                if (response.status === 200) {
                    await response.json().then((fetchedData) => {
                        console.log(fetchedData);
                        if (fetchedData.status === false || fetchedData.status === "noPermission") {
                            setBasicAlert({ variant: "danger", message: t(`error.${fetchedData.code}`), fading: null });
                            setDisableSubmitting(false);
                        } else {
                            setBasicAlert({ variant: "success", message: t(`success.addNewBarItemCategory`), fading: 2000 });
                            setCategory({ code: "", name: "", priority: "", blobID: null });
                            setCatIcon(null);
                            setDisableSubmitting(true);
                        }
                    });
                }
            } catch (err) {
                setBasicAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})`, fading: null });
            }
        };

        const insertCategoryIcon = async () => {
            const data = new FormData();
            data.append("file", catIcon);
            try {
                const response = await fetch(`${fetchURL}/add-bar-item-icon`, {
                    credentials: "include",
                    method: "POST",
                    body: data,
                });

                if (response.status === 200) {
                    await response.json().then((fetchedData) => {
                        console.log(fetchedData);
                        if (fetchedData.status === false || fetchedData.status === "noPermission") {
                            setBasicAlert({ variant: "danger", message: t(`error.${fetchedData.code}`), fading: null });
                            setDisableSubmitting(false);
                        } else {
                            insertNewCategory(fetchedData.blobID);
                        }
                    });
                }
            } catch (err) {
                setBasicAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})`, fading: null });
                setDisableSubmitting(false);
            }
        };

        const checkBarItemCategoryUniqueness = async () => {
            const passData = {
                categoryCode: category.code,
            };
            try {
                const response = await fetch(`${fetchURL}/check-bar-item-category-uniqueness`, {
                    credentials: "include",
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(passData),
                });

                if (response.status === 200) {
                    await response.json().then((fetchedData) => {
                        console.log(fetchedData);
                        if (fetchedData.status === false || fetchedData.status === "noPermission") {
                            setBasicAlert({ variant: "danger", message: t(`error.${fetchedData.code}`), fading: null });
                        } else {
                            if (category.selectedIcon) {
                                if (!catIcon.blobID) insertCategoryIcon();
                                else insertNewCategory(catIcon.blobID);
                            } else insertNewCategory(null);
                        }
                    });
                }
            } catch (err) {
                setBasicAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})`, fading: null });
            }
        };

        if (checkAndAdd) checkBarItemCategoryUniqueness();

        return () => {
            setCheckAndAdd(false);
        };
    }, [checkAndAdd]);
    return (
        <Fragment>
            <NavDetails title={`${currentPage.nav}.${currentPage.subpage}`} />
            <Form onSubmit={handleCheckValidation} autoComplete="off">
                <div className="scrolled-content">
                    <Container className="py-3">
                        <Row>
                            <Col xs={12}>
                                <Form.Label>
                                    {t("barItemCategories.code")} <span className="required">*</span>
                                </Form.Label>
                                <p className="text-small mb-3">{t("barItemCategories.codeHelper")}</p>
                            </Col>
                            <Col lg={7} xs={12}>
                                <TextForm
                                    value={category.code}
                                    onChangeValue={(e) => {
                                        setCategory({ ...category, [e.target.name]: e.target.value });
                                        setDisableSubmitting(false);
                                    }}
                                    req
                                    placeholderTxt={t("barItemCategories.codePlaceholder")}
                                    name="code"
                                    id="barItemCatCode"
                                    size="md"
                                />
                            </Col>
                            <Col xs={12}>
                                <Form.Label>
                                    {t("barItemCategories.name")} <span className="required">*</span>
                                </Form.Label>
                                <p className="text-small">{t("barItemCategories.nameHelper")}</p>
                                <TextForm
                                    value={category.name}
                                    onChangeValue={(e) => {
                                        setCategory({ ...category, [e.target.name]: e.target.value });
                                        setDisableSubmitting(false);
                                    }}
                                    req
                                    placeholderTxt={t("barItemCategories.namePlaceholder")}
                                    name="name"
                                    id="barItemCatName"
                                    size="md"
                                />
                            </Col>
                            <Col xs={12}>
                                <Form.Label>
                                    {t("barItemCategories.priority")} <span className="required">*</span>
                                </Form.Label>
                                <p className="text-small mb-3">{t("barItemCategories.priorityHelper")}</p>
                            </Col>
                            <Col xs={12} lg={6}>
                                <Form.Control
                                    type="number"
                                    placeholder={t("barItemCategories.priorityPlaceholder")}
                                    name="priority"
                                    id="barItemCatPriority"
                                    value={category.priority}
                                    onChange={(e) => {
                                        document.getElementById("barItemCatPriority").classList.remove("is-invalid");
                                        setCategory({ ...category, [e.target.name]: e.target.value });
                                        setDisableSubmitting(false);
                                    }}
                                    className="req"
                                    size="md"
                                />
                            </Col>
                        </Row>
                        <Row>
                            <ChooseTileIcon
                                icon={catIcon}
                                id="catIcon"
                                onChooseIcon={(file) => {
                                    setCatIcon(file);
                                    setDisableSubmitting(false);
                                    setCategory({ ...category, selectedIcon: true });
                                }}
                            />
                        </Row>
                        <Row>
                            <Col>
                                <Button variant="primary" type="submit" disabled={disableSubmitting} className="my-3" size="lg">
                                    {t("add")}
                                </Button>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </Form>
        </Fragment>
    );
};

export default AddBarItemCategory;
