import React, { createContext, Fragment, useContext, useEffect, useState } from "react";
import MainNavbar from "../navigation/MainNavbar";
import $ from "jquery";
import ConfirmEscapeModal from "../modals/ConfirmEscapeModal";
import { BasicContext } from "../../App";
import { useTranslation } from "react-i18next";
import LoadingSpinnerInner from "../spinners/LoadingSpinnerInner";
import Sell from "../pages/sell/Sell";
import { Container } from "react-bootstrap";
import BarClients from "../pages/barClients/BarClients";
import Duties from "../pages/duties/Duties";
import AdminPanel from "../pages/adminPanel/AdminPanel";
import Barmans from "../pages/barmans/Barmans";
import ScanDuty from "../pages/ScanDuty";

export const LoginDataContext = createContext();

const LoggedPages = () => {
    const { setBasicAlert, setLoginData, loginData, activeEvent, fetchURL } = useContext(BasicContext);
    const { t } = useTranslation();

    const startPage = { nav: "sell", subpage: "sellMain", params: "" };

    const [currentPage, setCurrentPage] = useState(null);
    const [nextPage, setNextPage] = useState(null);
    const [previousPage, setPreviousPage] = useState([]);
    const [confirmEscape, setConfirmEscape] = useState(false);
    const [changedValuesInFormes, setChangedValuesInFormes] = useState(false);
    const [cart, setCart] = useState([]);
    const [loggedUserQR, setLoggedUserQR] = useState(null); //user, który jest wymagany przy niektórych dodatkowych autoryzacjach
    const [duty, setDuty] = useState(null);

    const goToPreviousPage = () => {
        if (changedValuesInFormes && !confirmEscape) setConfirmEscape({ show: false, action: "prev" });
        else {
            //console.log("prev");
            const prevPageArray = [...previousPage];
            setCurrentPage(prevPageArray[prevPageArray.length - 1]);
            prevPageArray.splice(-1, 1);
            setPreviousPage(prevPageArray);
        }
    };

    const goToNextPage = () => {
        console.log("goToNextPage");
        const next = nextPage,
            current = currentPage;
        setPreviousPage([...previousPage, current]);
        setCurrentPage(next);
        setNextPage(null);
    };

    const returnToHomePage = () => {
        setLoginData({ ...loginData, loggedIn: false });
        setBasicAlert({ variant: "info", message: t(`session.sessionExpired`), fading: null });
        $("#showNotLoggedPages").fadeIn(700);
    };

    //uruchom za każdym razem kiedy user chce przejść do następnego widoku
    useEffect(() => {
        if (nextPage) {
            console.log("nextPage");
            //jeśli nastąpiły jakieś zmiany w formularzu, najpierw pokaż modal
            /*  if (changedValuesInFormes) setConfirmEscape({ show: false, action: "next" });
            else goToNextPage(); */
            goToNextPage();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [nextPage]);

    useEffect(() => {
        const getCurrentDuty = async () => {
            try {
                const passData = { activeEvent: activeEvent };
                const response = await fetch(`${fetchURL}/get-current-duty`, {
                    credentials: "include",
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(passData),
                });

                if (response.status === 200) {
                    await response.json().then((fetchedData) => {
                        console.log(fetchedData);

                        if (fetchedData.status === false || fetchedData.status === "noPermission") setBasicAlert({ variant: "danger", message: t(`error.${fetchedData.code}`), fading: null });
                        else {
                            if (fetchedData.isDutyBegin) {
                                setDuty(fetchedData.duty);
                                setCurrentPage({ nav: "scanDuty", subpage: "", params: "" });
                            } else setCurrentPage(startPage);
                        }
                    });
                }
            } catch (err) {
                setBasicAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})`, fading: null });
            }
        };

        if (activeEvent && activeEvent !== "noActiveEvent") getCurrentDuty();
        else setCurrentPage(startPage);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (confirmEscape.show && confirmEscape.action === "prev") {
            setChangedValuesInFormes(false);
            setConfirmEscape({ show: false, action: null });
            goToPreviousPage();
        } else if (confirmEscape.show && confirmEscape.action === "next") {
            setChangedValuesInFormes(false);
            setConfirmEscape({ show: false, action: null });
            goToNextPage();
        }
    }, [confirmEscape]);

    /* console.log("previousPages: ", previousPage);
    console.log("nextPage: ", nextPage);
    console.log("currentPage: ", currentPage); */

    return (
        <LoginDataContext.Provider
            value={{
                currentPage,
                setConfirmEscape,
                setChangedValuesInFormes,
                returnToHomePage,
                goToPreviousPage,
                confirmEscape,
                changedValuesInFormes,
                setNextPage,
                setCurrentPage,
                cart,
                setCart,
                loggedUserQR,
                setLoggedUserQR,
            }}
        >
            <Container fluid>
                {!currentPage ? (
                    <div className="py-4">
                        <LoadingSpinnerInner />
                    </div>
                ) : (
                    <Fragment>
                        {currentPage.nav === "scanDuty" ? (
                            <ScanDuty duty={duty} />
                        ) : (
                            <Fragment>
                                <MainNavbar />
                                {currentPage.nav === "sell" && <Sell />}
                                {currentPage.nav === "barClients" && <BarClients />}
                                {currentPage.nav === "duties" && <Duties />}
                                {currentPage.nav === "barmans" && <Barmans />}
                                {currentPage.nav === "adminPanel" && <AdminPanel />}
                            </Fragment>
                        )}
                    </Fragment>
                )}
            </Container>
            {confirmEscape.show && <ConfirmEscapeModal />}
        </LoginDataContext.Provider>
    );
};

export default LoggedPages;
