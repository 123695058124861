import React, { Fragment, useContext, useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Dropdown } from "semantic-ui-react";
import { BasicContext } from "../../App";
import LoadingProgressBar from "../spinners/LoadingProgressBar";

const BarmanRolesDropdown = (props) => {
    const { setBasicAlert, fetchURL } = useContext(BasicContext);
    const { t } = useTranslation();
    const [loading, setLoading] = useState(true);
    const [barmanRolesList, setBarmanRolesList] = useState(null);

    useEffect(() => {
        //lista userów, którzy w danym wydarzeniu nie mają jeszcze żadnego klienta
        const getRolesList = async () => {
            try {
                const response = await fetch(`${fetchURL}/barman-roles-list`, {
                    credentials: "include",
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                });

                if (response.status === 200) {
                    await response.json().then((fetchedData) => {
                        console.log(fetchedData);
                        if (fetchedData.status === false || fetchedData.status === "noPermission") {
                            setBasicAlert({ variant: "danger", message: t(`error.${fetchedData.code}`), fading: null });
                        } else {
                            setBarmanRolesList(fetchedData.list);
                            setLoading(false);
                        }
                    });
                }
            } catch (err) {
                setBasicAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})`, fading: null });
            }
        };
        getRolesList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Fragment>
            {loading ? (
                <LoadingProgressBar />
            ) : (
                <Fragment>
                    {!props.withoutLabel && (
                        <Form.Label>
                            {t("users.role")} {<span className="required">*</span>}
                        </Form.Label>
                    )}
                    <Dropdown
                        placeholder={t("select")}
                        fluid
                        selection
                        options={barmanRolesList}
                        onChange={(event, { value }) => {
                            props.onSelectRole(value);
                        }}
                        className="mb-3"
                        id="barmanRolesList"
                        value={props.selectedRole}
                    />
                </Fragment>
            )}
        </Fragment>
    );
};

export default BarmanRolesDropdown;
