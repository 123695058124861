import React, { Fragment, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { BasicContext } from "../../../App";
import PermissionModal from "../../modals/PermissionModal";
import { LoginDataContext } from "../../structure/LoggedPages";
import AddBarItem from "./AddBarItem";
import AddBarItemCategory from "./AddBarItemCategory";
import AssortmentList from "./AssortmentList";

const AdminPanel = () => {
    const { setBasicAlert, fetchURL, setQRscanner, QRData, setQRData, activeEvent } = useContext(BasicContext);
    const { currentPage, setLoggedUserQR, loggedUserQR } = useContext(LoginDataContext);
    const [showPermissionModal, setShowPermissionModal] = useState(null);
    const [permissionAllowed, setPermissionAllowed] = useState(false);

    const { t } = useTranslation();

    useEffect(() => {
        setShowPermissionModal("ADMIN");
        setPermissionAllowed(false);
    }, [currentPage]);

    console.log(showPermissionModal, loggedUserQR);
    useEffect(() => {
        if (showPermissionModal) setLoggedUserQR(null);
    }, [showPermissionModal]);

    useEffect(() => {
        const checkPermission = async () => {
            try {
                const response = await fetch(`${fetchURL}/check-permission`, {
                    credentials: "include",
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        qrCode: QRData,
                        type: showPermissionModal,
                        activeEvent: activeEvent,
                    }),
                });

                if (response.status === 200) {
                    await response.json().then((fetchedData) => {
                        console.log(fetchedData);
                        if (fetchedData.status === false || fetchedData.status === "noPermission") {
                            setBasicAlert({ variant: "danger", message: t(`error.${fetchedData.code}`), fading: null });
                        } else {
                            setLoggedUserQR(fetchedData.userID);
                            setShowPermissionModal(null);
                            setPermissionAllowed(true);
                        }
                    });
                }
            } catch (err) {
                setBasicAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})`, fading: null });
            }
        };

        if (QRData) checkPermission();

        return () => {
            setQRData(null);
            setQRscanner(false);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [QRData]);

    return (
        <Fragment>
            {permissionAllowed && (
                <Fragment>
                    {currentPage.subpage === "addBarItem" && <AddBarItem />}
                    {currentPage.subpage === "addBarItemCategory" && <AddBarItemCategory />}
                    {currentPage.subpage === "assortmentList" && <AssortmentList />}
                </Fragment>
            )}

            {showPermissionModal && <PermissionModal onClose={() => setShowPermissionModal(null)} option={showPermissionModal} show />}
        </Fragment>
    );
};

export default AdminPanel;
